<template>
<div>
    <KpiHeader
        v-if="location"

        title="Country Overview"
        :location="location"
        :tabs="tabs"
        :disable_title_meta="true"
    />
    <SelectCountry
        v-if="!$store.getters.market"
        @selectedCountry="selected_country"
    />
    
    <div v-else>
        <div v-if="countries" class="options-bar mt">
            <div  class="label-container flex mr">
                <div :class="`flag-icon flag-icon-${$store.getters.market.toLowerCase()} flag-position`"></div>
                <div>
                    <p>Base Country</p>
                    <DropDown
                        :key="$store.getters.market"
                        :options="countries.options"
                        :values="countries.values"
                        :default="$store.getters.market"
                        @updateDropDown="update_view"
                    />
                </div>
            </div>
            <div class="label-container flex ml">
                <div v-if="country_filter" :class="`flag-icon flag-icon-${country_filter.toLowerCase()} flag-position`"></div>
                <div>
                    <p>Comparison Country</p>
                    <DropDown
                        :key="country_filter"
                        :options="countries.options"
                        :values="countries.values"
                        :default="country_filter"
                        @updateDropDown="update_country"
                    />
                </div>
            </div>
        </div>
        <div v-if="!country_filter">
            Please select a comparison Country
        </div>
        <div v-else>
            <Table
                v-if="table.render"
                :headers="table.headers"
                :rows="table.rows"
                :icon-settings="table.icon_settings"
                :decimal-settings="table.decimal_settings"
                :data-settings="{ nullify_zeros: true }"
                :meta-settings="{ is_selectable: false }"
                :sort-settings="{ 'Rank (Country)': { asc: true } }"

                class="ms1p5 no-all"
            />
            <div v-else>There is no data for <b>{{$store.getters.selected_country_name}}</b></div>
        </div>
    </div>
</div>
</template>

<script>
import DropDown from '@/components/ui/DropDown.vue';
import KpiHeader from '@/components/ui/KPIheader.vue';
import SelectCountry from '@/components/ui/SelectCountry.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';

export default {
    props: {
        market: String
    },
    components: {
        DropDown,
        KpiHeader,
        SelectCountry,
        Table
    },
    data: function() {
        return {
            location: '/country-overview',
            table: {
                headers: [],
                rows: [],
                render: false,
                icon_settings: {},
                decimal_settings: {}
            },
            dropdown_render: false,
            country_filter: null,
            country_selected: false
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
        this.country_filter = localStorage['benchmark_country'] || this.country_filter;
        this.$store.commit('set_routing', false);
        this.get_data();
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        generate_tabs: function() {
            return [
                {name: 'By Country', link: `${this.location}/country`},
                {name: 'KPI List', link: `${this.location}/kpi/${this.$store.getters.market}`},
                {name: 'Benchmark', link: `${this.location}/benchmark`}
            ];
        },
        get_data: function() {
            this.table.render = false;
            if (!this.$store.getters.market || !this.$store.state.countries.all) return;
            this.$http.post('/country-overview/benchmark', {
                market: this.$store.getters.market,
                benchmark: this.country_filter
            }).then(
                succ => {
                    const d = succ.data;
                    let country_list = this.$store.state.countries;
                    let selected_country = country_list.options[country_list.values.indexOf(this.country_filter)];

                    this.table.headers = [ // second version of the table with double headers
                        [
                            {'': 1},
                            {'': 1, alias: 'Rank (Country)'},
                            {'Value': 3},
                            {'vs Goal': 2},
                            {'Trend': 2}
                        ],
                        [
                            {'': 1},
                            {'Rank (Country)': 1},

                            {[`${this.$store.getters.selected_country_name}`]: 1, alias: 'Country value'},
                            {[`${selected_country}`]: 1, alias: 'Benchmark value'},
                            {'Difference to Benchmark': 1},

                            {[`${this.$store.getters.selected_country_name}`]: 1, alias: 'Country goal'},
                            {[`${selected_country}`]: 1, alias: 'Benchmark goal'},

                            {[`${this.$store.getters.selected_country_name}`]: 1, alias: 'Country Trend'},
                            {[`${selected_country}`]: 1, alias: 'Benchmark Trend'}
                        ]
                    ];

                    const keys = [
                        't_sub_area',
                        't_current_country_rank',
                        't_country_value',
                        't_benchmark_value',
                        't_diff_to_benchmark',
                        't_current_vs_goal',
                        't_benchmark_vs_goal',
                        't_current_trend',
                        't_benchmark_trend'
                    ];
                    this.table.icon_settings = {
                        'Country Trend': {
                            icon: 'arrow-only',
                            comparison: 0,
                        },
                        'Benchmark Trend': {
                            icon: 'arrow-only',
                            comparison: 0,
                        },
                        'Country goal': {
                            icon: 'circle',
                            comparison: 0,
                        },
                        'Benchmark goal': {
                            icon: 'circle',
                            comparison: 0,
                        },
                        'Difference to Benchmark': {
                            fix: { post: '%' }
                        }
                    };
                    this.table.decimal_settings = {
                        global: 1,
                        "Rank (Country)": { "precision": 0 },
                    };
                    this.table.rows = [];
                    d.forEach(row => {
                        if (!row) return;
                        let temp = [];
                        keys.map(key => {
                            if (key.includes('t_current_country_rank')) {
                                temp.push(Number(row[key]));
                            } else {
                                temp.push(row[key]);
                            }
                        });
                        this.table.rows.push(temp);
                    });
                    this.dropdown_render = true;
                    this.table.render = true;

                    
                    // this.table.headers = [[ // This is the design from the ui designer, might be good to keep for a bit - Ben 13/10/2021
                    //     {'': 1},
                    //     {'Rank (Country)': 1},

                    //     {'Value': 1},
                    //     {'vs Goal': 1},
                    //     {'Trend': 1},
                    //     {'Difference to Benchmark': 1},

                    //     {[`${selected_country} Value`]: 1},
                    //     {[`${selected_country} vs Goal`]: 1},
                    //     {[`${selected_country} Trend`]: 1,}
                    // ]];

                    // const keys = [
                    //     't_sub_area',
                    //     't_current_country_rank',
                    //     't_country_value',
                    //     't_current_vs_goal',
                    //     't_current_trend',

                    //     't_diff_to_benchmark',

                    //     't_benchmark_value',
                    //     't_benchmark_vs_goal',
                    //     't_benchmark_trend'
                    // ];
                    // this.table.icon_settings = {
                    //     'Trend': {
                    //         icon: 'arrow-only',
                    //         comparison: 0,
                    //     },
                    //     [`${selected_country} Trend`]: {
                    //         icon: 'arrow-only',
                    //         comparison: 0,
                    //     },
                    //     'vs Goal': {
                    //         icon: 'circle',
                    //         comparison: 0,
                    //     },
                    //     [`${selected_country} vs Goal`]: {
                    //         icon: 'circle',
                    //         comparison: 0,
                    //     },
                    //     'Difference to Benchmark': {
                    //         fix: { po
                    //     keys.map(key => {
                    //         if (key.includes('t_current_country_rank')) {
                    //             temp.push(Number(row[key]));
                    //         } else {
                    //             temp.push(row[key]);
                    //         }
                    //     });
                    //     return temp;
                    // });
                    
                    // this.table.rows = d.map(row => {
                    //     let temp = [];
                    //     keys.map(key => {
                    //         if (key.includes('t_current_country_rank')) {
                    //             temp.push(Number(row[key]));
                    //         } else {
                    //             temp.push(row[key]);
                    //         }
                    //     });
                    //     return temp;
                    // });
                    // this.table.render = true;
                    // this.table.render = true;st: '%' }
                    //     },
                    // };
                    // this.table.decimal_settings = {
                    //     global: 1,
                    //     "Rank (Country)": { "precision": 0 },
                    // };
                    
                    // this.table.rows = d.map(row => {
                    //     let temp = [];
                    //     keys.map(key => {
                    //         if (key.includes('t_current_country_rank')) {
                    //             temp.push(Number(row[key]));
                    //         } else {
                    //             temp.push(row[key]);
                    //         }
                    //     });
                    //     return temp;
                    // });
                    // this.table.render = true;
                },
                this.$helper.error
            );
        },
        selected_country: function() {
            this.dropdown_render = false;
        },
        update_country: function(filter) {
            this.country_filter = filter;
            localStorage['benchmark_country'] = filter;
            this.get_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market,
                location: `${this.location}/benchmark`
            });
        }
    },
    computed: {
        countries: function() {
            let temp = this.$store.state.countries.all;
            if (!temp) return;
            let values = [];
            let options = [];
            temp.forEach(country => {
                values.push(country.market);
                options.push(country.caption)
            });
            return {values: values, options: options}
        },
        tabs: function() {
            return [
                {name: 'By Country', link: `${this.location}/country`},
                {name: 'KPI List', link: `${this.location}/kpi/${this.$store.getters.market}`},
                {name: 'Benchmark', link: `${this.location}/benchmark`}
            ]
        }
    },
    watch: {
        '$store.getters.market': function(market) {
            this.update_view(market);
            this.get_data();
        },
        '$store.getters.countries': function() {
            this.dropdown_render = false;
            this.country_filter = this.country_filter || this.$store.state.countries.values[1];
            this.get_data();
        },
    }
}
</script>

<style scoped>
.flag-position {
    width: 3rem;
    margin-top: 1.5rem;
}
</style>
