var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title-section" }, [_vm._v(" Select a Country ")]),
    _c(
      "div",
      { staticClass: "columns is-multiline is-centered pt" },
      _vm._l(_vm.displayed_countries, function (country, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "column is-2 country-box cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.update_filter(country)
              },
            },
          },
          [
            _c("div", {
              staticClass: "dropdown-flag",
              class: _vm.render_flag(country),
            }),
            _c("div", { staticClass: "country-title" }, [
              _vm._v(_vm._s(country.caption)),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }