var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.location
        ? _c("KpiHeader", {
            attrs: {
              title: "Country Overview",
              location: _vm.location,
              tabs: _vm.tabs,
              disable_title_meta: true,
            },
          })
        : _vm._e(),
      !_vm.$store.getters.market
        ? _c("SelectCountry", { on: { selectedCountry: _vm.selected_country } })
        : _c("div", [
            _vm.countries
              ? _c("div", { staticClass: "options-bar mt" }, [
                  _c("div", { staticClass: "label-container flex mr" }, [
                    _c("div", {
                      class: `flag-icon flag-icon-${_vm.$store.getters.market.toLowerCase()} flag-position`,
                    }),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Base Country")]),
                        _c("DropDown", {
                          key: _vm.$store.getters.market,
                          attrs: {
                            options: _vm.countries.options,
                            values: _vm.countries.values,
                            default: _vm.$store.getters.market,
                          },
                          on: { updateDropDown: _vm.update_view },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "label-container flex ml" }, [
                    _vm.country_filter
                      ? _c("div", {
                          class: `flag-icon flag-icon-${_vm.country_filter.toLowerCase()} flag-position`,
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Comparison Country")]),
                        _c("DropDown", {
                          key: _vm.country_filter,
                          attrs: {
                            options: _vm.countries.options,
                            values: _vm.countries.values,
                            default: _vm.country_filter,
                          },
                          on: { updateDropDown: _vm.update_country },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            !_vm.country_filter
              ? _c("div", [_vm._v(" Please select a comparison Country ")])
              : _c(
                  "div",
                  [
                    _vm.table.render
                      ? _c("Table", {
                          staticClass: "ms1p5 no-all",
                          attrs: {
                            headers: _vm.table.headers,
                            rows: _vm.table.rows,
                            "icon-settings": _vm.table.icon_settings,
                            "decimal-settings": _vm.table.decimal_settings,
                            "data-settings": { nullify_zeros: true },
                            "meta-settings": { is_selectable: false },
                            "sort-settings": {
                              "Rank (Country)": { asc: true },
                            },
                          },
                        })
                      : _c("div", [
                          _vm._v("There is no data for "),
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.$store.getters.selected_country_name)
                            ),
                          ]),
                        ]),
                  ],
                  1
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }