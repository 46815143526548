<template>
    <div>
        <div class="title-section">
            Select a Country
        </div>
        <div class="columns is-multiline is-centered pt">
            <div class="column is-2 country-box cursor-pointer"  @click="update_filter(country)" v-for="(country, i) in displayed_countries" :key="i">
                <div class="dropdown-flag" v-bind:class="render_flag(country)"></div>
                <div class="country-title">{{country.caption}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        render_flag: function(country) {
            if (country) {
                let key = `flag-icon flag-icon-${country.market.toLowerCase()}`;
                let obj = {};
                obj[key] = true;
                return obj;
            } 
            return {};
        },
        update_filter: function(country) {
            this.$store.state.selected.country = country.caption;
            this.$store.state.selected.market = country.market;
            this.$store.state.selected.flag = country.market;
            localStorage.setItem('saved-countries', JSON.stringify({ caption: country.caption, market: country.market }));
            this.$emit('selectedCountry', country.market);
        },
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    computed: {
        displayed_countries: function() {
            let temp = this.$store.state.countries.all;
            if (temp) {
                temp = temp.sort((a, b) => {
                    if (a.caption < b.caption) {
                        return -1;
                    } else if (a.caption > b.caption) {
                        return 1;
                    }
                    return 0;
                });
            }
            return temp;
        }
    }
}
</script>

<style scoped>
.country-box {
    transition: 0s;
}

.country-box:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 3px #d4d4d4;
}

.country-title{
    height: 3em;
}

.flag-icon {
    width: 100%;
    height: 4em;
    line-height: auto;
}

.title-section {
    background-color:#FFF;
    color:#000;
    font-size: 1.5rem;
    padding: 1.5rem;
}
</style>